import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import BlogPost from '../../components/BlogPost';
import Comment from '../../types/comments';

interface BlogPostProps {
  data: {
    post: any;
    comments: {
      edges: {
        node: Comment;
      }[];
    };
  };
}

const BlogPostTemplate: FC<BlogPostProps> = ({ data }) => {
  const { post, comments } = data;
  const { title, tags } = post.frontmatter;
  const commentNodes = comments.edges.map((comment) => comment.node);
  return (
    <Layout>
      <SEO
        title={title}
        keywords={tags}
        description={post.frontmatter.description}
        type="article"
        slug={post.frontmatter.slug}
        article={post}
        comments={commentNodes}
      />
      <BlogPost post={post} comments={commentNodes} />
    </Layout>
  );
};

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    post: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        date
        dateUpdated
        category
        tags
        slug
      }
    }
    comments: allCommentsYaml(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          id
          slug
          name
          date
          message
        }
      }
    }
  }
`;

export default BlogPostTemplate;

import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { format } from 'date-fns';
import readingTime from '../../utils/readingTime';
import slugify from '../../utils/slugify';
import * as styles from './LatestPostStyles.module.scss';

interface LatestPostProps {
  post: any;
  blogPostPage?: boolean;
}

const LatestPost: FC<LatestPostProps> = ({ post, blogPostPage = false }) => {
  return (
    <article
      className={classNames(
        styles.latestPost,
        blogPostPage && styles.blogPostPage,
      )}
    >
      {!blogPostPage && <p className={styles.heading}>Latest post</p>}
      {blogPostPage ? (
        <h1 className={styles.title}>{post.frontmatter.title}</h1>
      ) : (
        <Link to={`/${post.frontmatter.slug}/`}>
          <h2 className={styles.title}>{post.frontmatter.title}</h2>
        </Link>
      )}
      {post.frontmatter.category && (
        <Link to={`/${slugify(post.frontmatter.category)}/`}>
          <h2 className={styles.category}>↳ {post.frontmatter.category}</h2>
        </Link>
      )}
      <p>
        {format(new Date(post.frontmatter.date), 'dd MMM yyyy')} |{' '}
        <span className={styles.readingTime}>{readingTime(post.html)}</span>
      </p>
    </article>
  );
};

export default LatestPost;

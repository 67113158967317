// extracted by mini-css-extract-plugin
export var black = "#000";
export var blogPostPage = "LatestPostStyles-module--blogPostPage--cdfa1";
export var blue = "#1818e0";
export var blueLight = "#d2dfef";
export var breakpointLarge = "1201px";
export var breakpointMedium = "769px";
export var breakpointSmall = "501px";
export var category = "LatestPostStyles-module--category--47219";
export var green = "#1fd56b";
export var grey = "#b9bdc1";
export var greyDark = "#9ba0a3";
export var greyDarker = "#6e6e77";
export var greyLight = "#f1f1f1";
export var heading = "LatestPostStyles-module--heading--d1f3d";
export var latestPost = "LatestPostStyles-module--latestPost--3b0bb";
export var readingTime = "LatestPostStyles-module--readingTime--1a4db";
export var red = "#f24822";
export var sizesLargeDesktop = "48px";
export var sizesLargeMobile = "28px";
export var sizesMediumDesktop = "24px";
export var sizesMediumMobile = "20px";
export var sizesSmallDesktop = "16px";
export var sizesSmallMobile = "12px";
export var sizesXSmall = "13px";
export var title = "LatestPostStyles-module--title--ca35d";
export var transitionDurationModalContent = "500ms";
export var transitionDurationModalOverlay = "200ms";
export var weightsBlack = "900";
export var weightsBold = "700";
export var weightsLight = "300";
export var weightsMedium = "500";
export var weightsRegular = "400";
export var weightsThin = "200";
export var white = "#fff";
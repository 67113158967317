import React, { FC } from 'react';
import LatestPost from '../LatestPost';
import Comments from '../Comments';
import Comment from '../../types/comments';

interface BlogPostProps {
  post: any;
  comments: Comment[];
}

const BlogPost: FC<BlogPostProps> = ({ post, comments }) => {
  return (
    <article>
      <LatestPost post={post} blogPostPage />

      <div>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>
    </article>
  );
};

export default BlogPost;
